import ReactModal from "react-modal";
import { useButtonId } from "../../../utils/hooks/usePageScopedId";
import Button from "../../shared/Button";

const PromoCodeIssueModal = ({ idBase, isOpen, headingText, children, onClose, onContinue }) => (
  <ReactModal
    ariaHideApp
    preventScroll
    shouldFocusAfterRender
    aria={{
      labelledby: `${idBase}ModalHeading`,
      describedby: `${idBase}ModalDescription`,
    }}
    bodyOpenClassName={null}
    className="grv-dialog grv-dialog--active"
    contentLabel={headingText}
    htmlOpenClassName={null}
    id={`${idBase}Modal`}
    isOpen={isOpen}
    overlayClassName="grv-dialog__overlay grv-dialog__overlay--dark"
    portalClassName="grv-portal"
    role="dialog"
  >
    <div className="grv-dialog__container">
      <h1 id={`${idBase}ModalHeading`} className="grv-dialog__heading grv-text__heading--large">
        {headingText}
      </h1>
      <button
        aria-label="close promotional code problem modal"
        id={useButtonId(`${idBase}ModalClose`)}
        className="grv-dialog__close"
        type="button"
        onClick={onClose}
      />
      <div id={`${idBase}ModalDescription`} className="grv-dialog__content">
        {children}
      </div>
      <div className="grv-dialog__buttons">
        <Button gravityType="text" id={useButtonId(`${idBase}ModalBack`)} onClick={onClose}>
          Back
        </Button>
        <Button id={useButtonId(`${idBase}ModalContinue`)} onClick={onContinue}>
          Continue
        </Button>
      </div>
    </div>
  </ReactModal>
);

export default PromoCodeIssueModal;
