import { Navigate, Route, Routes, useParams } from "react-router-dom";
import ReactModal from "react-modal";
import useLoadPageContent from "./utils/hooks/useLoadPageContent";
import usePageEvents from "./utils/hooks/usePageEvents";
import useInteractionEvents from "./utils/hooks/useInteractionEvents";
import GettingStarted from "./components/pages/GettingStarted";
import LegalEntity from "./components/pages/LegalEntity";
import ProductSelection from "./components/pages/ProductSelection";
import PersonalInfo from "./components/pages/PersonalInfo";
import PersonalContactInfo from "./components/pages/PersonalContactInfo";
import BusinessDetails from "./components/pages/BusinessDetails";
import RiskyBusinessFinancialActivities from "./components/pages/RiskyBusiness";
import BusinessContactDetails from "./components/pages/BusinessContactDetails";
import ApplicationReview from "./components/pages/ApplicationReview";
import TermsAndConditions from "./components/pages/TermsAndConditions/TermsAndConditions";
import BusinessOwnershipInfo from "./components/pages/BusinessOwnershipInfo";
import BusinessOwnersTopLevel from "./components/pages/BusinessOwnersTopLevel";
import BusinessOwnersIndividualDetails from "./components/pages/BusinessOwnersIndividualDetails";
import BusinessControllerAndOwnersReview from "./components/pages/BusinessControllerAndOwnersReview";
import { SessionEnded, SessionExpired } from "./components/pages/Timeout";
import OutOfFootprint from "./components/pages/OutOfFootprint";
import useQueryParams from "./utils/hooks/useQueryParams";
import { DecisionApproved, DecisionDeclined } from "./components/pages/Decision";
import BranchFailedCreateApplication from "./components/pages/BranchFailedCreateApplication";
import ErrorBoundary from "./components/shared/ErrorBoundary";
import userIcon from "./assets/user.svg";
import { Protected, Terminal } from "./utils/handleForceBrowsing";
import ApplicationUnavailable from "./components/pages/ApplicationUnavailable";
import LoadingSpinnerPage from "./components/shared/LoadingSpinnerPage";
import { SingleSignOnSignIn, SingleSignOnEnroll } from "./components/pages/SingleSignOn";
import { ExistingCustomerCheck } from "./components/pages/ExistingCheckTransitionals";
import useInitialRouting from "./utils/hooks/useInitialRouting";
import useLoginCookie from "./utils/hooks/useLoginCookie";
import useLoadABTests from "./utils/hooks/useLoadABTests";
import {
  ApplicantInformationPrefill,
  ApplicantInformationPrefillIncorrectInfo,
} from "./components/pages/ApplicantInformationPrefill";
import {
  BusinessInformationPrefill,
  BusinessInformationPrefillIncorrectInfo,
} from "./components/pages/BusinessInformationPrefill";
import { RaDataFailure, CannotProceed, UnsupportedTaxID } from "./components/pages/Blocked";
import DecisionAdditionalTime from "./components/pages/Decision/DecisionAdditionalTime";
import ExistingBusinessSelection from "./components/pages/ExistingBusinessSelection";
import AdditionalExistingBusinessInformation from "./components/pages/AdditionalExistingBusinessInformation";
import ManualReviewFAQ from "./components/pages/ManualReviewFAQ/ManualReviewFAQ";
import { GovIDEmail, GovIDSMS, GovIDSelfieEmail, GovIDSelfieSMS } from "./components/pages/GovID";
import MissingPersonalEmail from "./components/pages/MissingPersonalEmail/MissingPersonalEmail";
import MissingPhoneNumber from "./components/pages/MissingPhoneNumber/MissingPhoneNumber";
import { RestartableError, NonRestartableError } from "./components/pages/TechnicalErrors";
import CustomerTypeCheck from "./components/pages/CustomerTypeCheck";
import RiskyBusinessIndustryActivities from "./components/pages/RiskyBusiness/RiskyBusinessIndustryActivities";
import RiskyBusinessAdditionalIndustryActivities from "./components/pages/RiskyBusiness/RiskyBusinessAdditionalIndustryActivities";
import InvalidEmailAddress from "./components/pages/InvalidEmailAddress";

ReactModal.setAppElement("#root");

// this constant is defined outside of the component to ensure it will never cause a rerender
const savedQueryParams = [
  "productsSelected",
  "trackingId",
  "applicationChannel",
  "webChannelEnabled",
  "branchCriticalTechErrorEnabled",
  "desiredLanding",
  "offerFulfillmentEnabled",
];

// this wrapper forces a full unmount/remount of the underlying BusinessOwnersIndividualDetails page
// this is necessary because otherwise the form does not fully unregister when navigating between individual details pages
// derived from: https://stackoverflow.com/a/49441836
// updated for react router v6, which no longer supports the render prop on routes
// the important part for forcing the remount is setting key equal to the index
// additionally, the index is passed as a prop to simplify the BO review page logic
const BusinessOwnersIndividualDetailsWrapper = () => {
  const { index } = useParams();
  return <BusinessOwnersIndividualDetails key={index} index={parseInt(index, 10)} />;
};

const App = () => {
  useLoginCookie();
  useLoadABTests();
  useQueryParams(savedQueryParams);
  useLoadPageContent();
  useInitialRouting("/loading");
  usePageEvents();
  useInteractionEvents();

  return (
    <ErrorBoundary>
      <Routes>
        {/* Loading page used during initial routing */}
        <Route
          exact
          path="loading"
          element={<LoadingSpinnerPage loadingSpinnerText="Loading the application..." />}
        />

        {/* Landing page candidates (AB test) */}
        <Route
          exact
          path="/"
          element={
            <Protected path="/">
              <GettingStarted />
            </Protected>
          }
        />
        <Route
          exact
          path="product-selection"
          element={
            <Protected path="/product-selection">
              <ProductSelection />
            </Protected>
          }
        />

        {/* Application flow */}
        <Route
          exact
          path="company-type"
          element={
            <Protected>
              <LegalEntity />
            </Protected>
          }
        />
        <Route
          exact
          path="applicant-information-prefilled"
          element={
            <Protected>
              <ApplicantInformationPrefill />
            </Protected>
          }
        />

        <Route
          exact
          path="customer-type-check"
          element={
            <Protected>
              <CustomerTypeCheck />
            </Protected>
          }
        />
        <Route
          exact
          path="personal-info"
          element={
            <Protected>
              <PersonalInfo />
            </Protected>
          }
        />
        <Route
          exact
          path="personal-contact-info"
          element={
            <Protected>
              <PersonalContactInfo />
            </Protected>
          }
        />
        <Route
          exact
          path="business-details"
          element={
            <Protected>
              <BusinessDetails />
            </Protected>
          }
        />
        <Route
          exact
          path="business-services/0"
          element={
            <Protected>
              <RiskyBusinessFinancialActivities />
            </Protected>
          }
        />
        <Route
          exact
          path="business-services/1"
          element={
            <Protected>
              <RiskyBusinessIndustryActivities />
            </Protected>
          }
        />
        <Route
          exact
          path="business-services/2"
          element={
            <Protected>
              <RiskyBusinessAdditionalIndustryActivities />
            </Protected>
          }
        />
        <Route
          exact
          path="business-contact-details"
          element={
            <Protected>
              <BusinessContactDetails />
            </Protected>
          }
        />
        <Route
          exact
          path="business-ownership-info"
          element={
            <Protected>
              <BusinessOwnershipInfo />
            </Protected>
          }
        />
        <Route
          exact
          path="business-owners-info"
          element={
            <Protected>
              <BusinessOwnersTopLevel />
            </Protected>
          }
        />
        <Route exact path="individual-business-owner-details">
          <Route
            exact
            path=":index"
            element={
              <Protected>
                <BusinessOwnersIndividualDetailsWrapper />
              </Protected>
            }
          />
        </Route>
        <Route
          exact
          path="business-controller-owners-review"
          element={
            <Protected>
              <BusinessControllerAndOwnersReview />
            </Protected>
          }
        />
        <Route
          exact
          path="application-review"
          element={
            <Protected>
              <ApplicationReview />
            </Protected>
          }
        />
        <Route
          exact
          path="terms-and-conditions"
          element={
            <Protected>
              <TermsAndConditions />
            </Protected>
          }
        />

        {/* Existing Customer pages */}
        <Route
          exact
          path="sign-in"
          element={
            <Protected>
              <SingleSignOnSignIn />
            </Protected>
          }
        />
        <Route
          exact
          path="enroll"
          element={
            <Protected>
              <SingleSignOnEnroll />
            </Protected>
          }
        />
        <Route
          exact
          path="existing-customer-check"
          element={
            <Protected>
              <ExistingCustomerCheck />
            </Protected>
          }
        />
        <Route
          exact
          path="existing-business-selection"
          element={
            <Protected>
              <ExistingBusinessSelection />
            </Protected>
          }
        />
        <Route
          exact
          path="business-information-prefilled"
          element={
            <Protected>
              <BusinessInformationPrefill />
            </Protected>
          }
        />
        <Route
          exact
          path="additional-existing-business-information"
          element={
            <Protected>
              <AdditionalExistingBusinessInformation />
            </Protected>
          }
        />

        {/* Ineligibility pages */}
        <Route
          exact
          path="out-of-footprint"
          element={
            <Terminal ignoreApplication>
              <OutOfFootprint />
            </Terminal>
          }
        />
        <Route
          exact
          path="missing-personal-email"
          element={
            <Terminal>
              <MissingPersonalEmail />
            </Terminal>
          }
        />
        <Route
          exact
          path="invalid-email-address"
          element={
            <Terminal>
              <InvalidEmailAddress />
            </Terminal>
          }
        />
        <Route
          exact
          path="applicant-information-incorrect"
          element={
            <Terminal>
              <ApplicantInformationPrefillIncorrectInfo />
            </Terminal>
          }
        />
        <Route
          exact
          path="existing-business-information-incorrect"
          element={
            <Terminal>
              <BusinessInformationPrefillIncorrectInfo />
            </Terminal>
          }
        />
        <Route
          exact
          path="manual-review-faq"
          element={
            <Terminal ignoreApplication>
              <ManualReviewFAQ />
            </Terminal>
          }
        />
        <Route
          exact
          path="unsupported-tax-id"
          element={
            <Terminal ignoreApplication>
              <UnsupportedTaxID />
            </Terminal>
          }
        />

        {/* Decision pages */}
        <Route exact path="decision">
          <Route
            exact
            path="approved"
            element={
              <Terminal>
                <DecisionApproved />
              </Terminal>
            }
          />
          <Route
            exact
            path="declined"
            element={
              <Terminal>
                <DecisionDeclined />
              </Terminal>
            }
          />
          <Route
            exact
            path="manual-review"
            element={
              <Terminal>
                <ManualReviewFAQ
                  iconSrc={userIcon}
                  headingText="We're reviewing your application"
                  questionOverride
                />
              </Terminal>
            }
          />
          <Route
            exact
            path="additional-time-needed"
            element={
              <Terminal>
                <DecisionAdditionalTime />
              </Terminal>
            }
          />
        </Route>

        {/* GovID pages */}
        <Route
          exact
          path="gov-id-email"
          element={
            <Terminal>
              <GovIDEmail />
            </Terminal>
          }
        />
        <Route
          exact
          path="gov-id-sms"
          element={
            <Terminal>
              <GovIDSMS />
            </Terminal>
          }
        />
        <Route
          exact
          path="gov-id-selfie-email"
          element={
            <Terminal>
              <GovIDSelfieEmail />
            </Terminal>
          }
        />
        <Route
          exact
          path="gov-id-selfie-sms"
          element={
            <Terminal>
              <GovIDSelfieSMS />
            </Terminal>
          }
        />
        <Route
          exact
          path="missing-home-and-mobile-phone-number"
          element={
            <Terminal>
              <MissingPhoneNumber />
            </Terminal>
          }
        />
        <Route
          exact
          path="missing-home-phone-number"
          element={
            <Terminal>
              <MissingPhoneNumber title="Missing Home Phone Number" />
            </Terminal>
          }
        />
        <Route
          exact
          path="missing-mobile-phone-number"
          element={
            <Terminal>
              <MissingPhoneNumber title="Missing Mobile Phone Number" />
            </Terminal>
          }
        />

        {/* Timeout pages */}
        <Route
          exact
          path="session-ended"
          element={
            <Terminal ignoreApplication>
              <SessionEnded />
            </Terminal>
          }
        />
        <Route
          exact
          path="timeout"
          element={
            <Terminal ignoreApplication>
              <SessionExpired />
            </Terminal>
          }
        />

        {/* Error pages */}
        <Route
          exact
          path="branch-application-not-created"
          element={
            <Terminal ignoreApplication>
              <BranchFailedCreateApplication />
            </Terminal>
          }
        />
        <Route
          exact
          path="application-unavailable"
          element={
            <Terminal ignoreApplication>
              <ApplicationUnavailable />
            </Terminal>
          }
        />
        <Route
          exact
          path="cannot-proceed"
          element={
            <Terminal ignoreApplication>
              <CannotProceed />
            </Terminal>
          }
        />
        <Route
          exact
          path="existing-data-failure"
          element={
            <Terminal ignoreApplication>
              <RaDataFailure />
            </Terminal>
          }
        />
        <Route
          exact
          path="something-went-wrong"
          element={
            <Terminal ignoreApplication>
              <NonRestartableError />
            </Terminal>
          }
        />
        <Route
          exact
          path="restart-application"
          element={
            <Terminal ignoreApplication>
              <RestartableError />
            </Terminal>
          }
        />
        {/* Unknown routes go to the landing page */}
        <Route path="*" element={<Navigate to="/" />} />
      </Routes>
    </ErrorBoundary>
  );
};

export default App;
